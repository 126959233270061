import React from "react";





const Footer = () => {
  return (
    <div className="footer-basic">
    <footer>
        <ul className="list-inline">
         
        </ul>
        <p className="copyright">&copy; Copyright 2023 Ratwerks.org</p>
    </footer>
    </div>
  );
};

export default Footer;
